import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import { BlogPostsHero } from "../sections/blog"

import { CtaGetDemo } from "components/cta"

const BlogPage = ({ data: { blog } }) => {
  const { blog_posts_hero, cta_get_demo } = useContentfulSection(blog.sections)

  const meta = useMeta(blog)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />

        <BlogPostsHero {...blog_posts_hero} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-max-width: 1200px;
`

export const query = graphql`
  {
    blog: contentfulPage(pageId: { eq: "blog" }) {
      ...Page
    }
  }
`

export default BlogPage
